
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import {
  ISupplier,
  ISupplierCreateRequest,
  ISupplierRequest,
  ISupplierUpdateRequest
} from "@/types/supplier";
import { SupplierRequest } from "@/models/supplier";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { IUser, IUserListRequest } from "@/types/user";

@Component({})
export default class extends Vue {
  //supplier
  @Action("supplier/adminGet")
  public getSupplier!: (supplier_id: number) => void;

  @Action("supplier/adminCreate")
  public create!: (params: ISupplierCreateRequest) => boolean;

  @Action("supplier/adminUpdate")
  public update!: (data: {
    supplier_id: number;
    params: ISupplierUpdateRequest;
  }) => boolean;

  @Action("supplier/adminDelete")
  public deleteSupplier!: (supplier_id: number) => boolean;

  @Action("supplier/adminIsUniqueName")
  public isUniqueName!: (params: ISupplierRequest) => boolean;

  @Action("supplier/adminIsUniqueShortName")
  public isUniqueShortName!: (params: ISupplierRequest) => boolean;

  @Getter("supplier/single")
  public supplier!: ISupplier;

  @Mutation("supplier/clear")
  public clear!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => boolean;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => boolean;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Mutation("user/clear")
  public clearUser!: () => void;

  //パラメータ定義
  public params: ISupplierRequest = new SupplierRequest();

  //変数定義
  public supplier_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public name_errors: string[] = [];
  public short_name_errors: string[] = [];
  public menu = {
    schedule_date: false
  };

  //ルール定義
  public rules = {
    name: [
      (v: string) => !!v || "サプライヤ名は必須です",
      (v: string) =>
        (v && v.length <= 50) || "サプライヤ名は50文字以下で入力してください"
    ],
    kana: [
      (v: string) => !!v || "ﾖﾐｶﾞﾅは必須です",
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    short_name: [
      (v: string) => !!v || "短縮表示名は必須です",
      (v: string) =>
        (v && v.length <= 20) || "短縮表示名は20文字以下で入力してください"
    ],
    postalcode: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    tel: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    fax: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    makers: [(v: number[]) => !!v.length || "メーカは必須です"],
    registration_no: [(v: string) => !!v || "登録番号は必須です"],
    schedule_date: [(v: string) => !!v || "登録予定日は必須です"],
    users: [(v: number[]) => !!v.length || "テクネ担当者は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.supplier_id = Number(this.$route.params.supplier_id);
    this.clear();
    this.clearMaker();
    this.clearUser();

    await Promise.all([
      this.getMakers({ per_page: 0 }),
      this.getUsers({ per_page: 0 })
    ]);

    // 編集時
    if (this.supplier_id) {
      await this.getSupplier(this.supplier_id);

      this.setDefault();
    }
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromSupplier(this.supplier);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.supplier_id) {
      result = await this.update({
        supplier_id: this.supplier_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteSupplier(this.supplier_id)) {
      this.$router.push(`/admin/supplier/list`);
    }
  }

  // サプライヤ名の二重登録チェック
  public async name_validate() {
    // 登録時
    if (!(await this.isUniqueName(this.params)) && !this.supplier.name) {
      return (this.name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueName(this.params)) &&
      this.supplier.name != this.params.name
    ) {
      return (this.name_errors = ["既に使用されています"]);
    }

    return (this.name_errors = []);
  }

  // 短縮表示名の二重登録チェック
  public async short_name_validate() {
    // 登録時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      !this.supplier.short_name
    ) {
      return (this.short_name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueShortName(this.params)) &&
      this.supplier.short_name != this.params.short_name
    ) {
      return (this.short_name_errors = ["既に使用されています"]);
    }

    return (this.short_name_errors = []);
  }

  // 登録ステータスを選んだ場合の挙動
  public registrationStatus() {
    this.params.schedule_date = "";
    this.params.registration_no = "";
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }
}
